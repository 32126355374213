<template>
  <v-container fluid>
    <v-row wrap>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-text-field
          class="white"
          label="Nome"
          placeholder="Ex.: ExpressFil"
          required
          outlined
          dense
          v-model="filial.nome"
          data-vv-as="nome"
          persistent-hint
          :hint="errors.first('adm_filiais.nome')"
          :error="errors.collect('adm_filiais.nome').length ? true : false"
          v-validate="'required|min:3'"
          data-vv-scope="adm_filiais"
          data-vv-validate-on="change"
          id="nome"
          name="nome"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-text-field
          class="white"
          label="CNPJ"
          placeholder="Ex.: 11.111.111/1111-11"
          required
          outlined
          dense
          v-model="filial.cnpj"
          data-vv-as="cnpj"
          persistent-hint
          :hint="errors.first('adm_filiais.cnpj')"
          :error="errors.collect('adm_filiais.cnpj').length ? true : false"
          v-validate="'required'"
          data-vv-scope="adm_filiais"
          data-vv-validate-on="change"
          id="cnpj"
          name="cnpj"
          v-mask="['##.###.###/####-##']"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-text-field
          class="white"
          label="Celular"
          placeholder="Ex.: (31) 9 9634-4240"
          required
          outlined
          dense
          v-model="filial.celular"
          data-vv-as="celular"
          persistent-hint
          :hint="errors.first('adm_filiais.celular')"
          :error="errors.collect('adm_filiais.celular').length ? true : false"
          v-validate="'required'"
          data-vv-scope="adm_filiais"
          data-vv-validate-on="change"
          id="celular"
          name="celular"
          v-mask="['(##) # ####-####']"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-text-field
          class="white"
          label="Telefone"
          placeholder="Ex.: (031) 35734-4240"
          outlined
          dense
          v-model="filial.telefone"
          v-mask="['(##) ####-####']"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-text-field
          class="white"
          label="E-mail"
          placeholder="Ex.: express.fil@express.com"
          required
          outlined
          dense
          v-model="filial.email"
          data-vv-as="email"
          persistent-hint
          :hint="errors.first('adm_filiais.email')"
          :error="errors.collect('adm_filiais.email').length ? true : false"
          v-validate="'required|email'"
          data-vv-scope="adm_filiais"
          data-vv-validate-on="change"
          id="email"
          name="email"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <v-select
          class="white"
          label="Responsável"
          :items="responsaveis"
          item-text="nome"
          item-value="id"
          placeholder="Selecione um usuário"
          outlined
          dense
          required
          v-model="filial.id_usuario"
          data-vv-as="responsável"
          persistent-hint
          :hint="errors.first('adm_filiais.responsavel')"
          :error="
            errors.collect('adm_filiais.responsavel').length ? true : false
          "
          v-validate="'required'"
          data-vv-scope="adm_filiais"
          data-vv-validate-on="change"
          id="responsavel"
          name="responsavel"
          :loading="loadings.loadingResponsavel"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-textarea
          class="white"
          ref="descricao"
          outlined
          dense
          name="input-7-4"
          label="Descrição"
          v-model="filial.descricao"
        ></v-textarea>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h2>Endereço</h2>
      </v-col>
      <!-- cep -->
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          ref="cep"
          label="Cep"
          placeholder="digite o cep"
          required
          outlined
          dense
          v-model="filial.endereco.cep"
          data-vv-as="cep"
          persistent-hint
          :hint="errors.first('adm_filiais.cep')"
          :error="errors.collect('adm_filiais.cep').length ? true : false"
          v-validate="'required'"
          data-vv-scope="adm_filiais"
          data-vv-validate-on="change|blur|input"
          id="cep"
          name="cep"
          @blur="searchCep()"
          v-mask="['#####-###']"
          :loading="loadings.loadingCep"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          ref="uf"
          label="Uf"
          placeholder="digite a uf"
          required
          outlined
          dense
          v-model="filial.endereco.uf"
          data-vv-as="uf"
          persistent-hint
          :hint="errors.first('adm_filiais.uf')"
          :error="errors.collect('adm_filiais.uf').length ? true : false"
          v-validate="'required|max:2'"
          data-vv-scope="adm_filiais"
          data-vv-validate-on="change|blur|input"
          id="uf"
          name="uf"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          ref="cidade"
          label="Cidade"
          placeholder="digite a cidade"
          required
          outlined
          dense
          v-model="filial.endereco.cidade"
          data-vv-as="cidade"
          persistent-hint
          :hint="errors.first('adm_filiais.cidade')"
          :error="errors.collect('adm_filiais.cidade').length ? true : false"
          v-validate="'required'"
          data-vv-scope="adm_filiais"
          data-vv-validate-on="change|blur|input"
          id="cidade"
          name="cidade"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          ref="bairro"
          label="Bairro"
          placeholder="digite a bairro"
          required
          outlined
          dense
          v-model="filial.endereco.bairro"
          data-vv-as="bairro"
          persistent-hint
          :hint="errors.first('adm_filiais.bairro')"
          :error="errors.collect('adm_filiais.bairro').length ? true : false"
          v-validate="'required'"
          data-vv-scope="adm_filiais"
          data-vv-validate-on="change|blur|input"
          id="bairro"
          name="bairro"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          ref="rua"
          label="Rua"
          placeholder="digite a rua"
          required
          outlined
          dense
          v-model="filial.endereco.rua"
          data-vv-as="rua"
          persistent-hint
          :hint="errors.first('adm_filiais.rua')"
          :error="errors.collect('adm_filiais.rua').length ? true : false"
          v-validate="'required'"
          data-vv-scope="adm_filiais"
          data-vv-validate-on="change|blur|input"
          id="rua"
          name="rua"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          ref="numero"
          label="Número"
          placeholder="digite a numero"
          required
          outlined
          dense
          type="number"
          v-model="filial.endereco.numero"
          data-vv-as="número"
          persistent-hint
          :hint="errors.first('adm_filiais.numero')"
          :error="errors.collect('adm_filiais.numero').length ? true : false"
          v-validate="'required'"
          data-vv-scope="adm_filiais"
          data-vv-validate-on="change|blur|input"
          id="numero"
          name="numero"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="6">
        <v-text-field
          ref="complemento"
          label="Complemento"
          placeholder="digite a complemento"
          required
          outlined
          dense
          v-model="filial.endereco.complemento"
          data-vv-as="complemento"
          persistent-hint
          :hint="errors.first('adm_filiais.complemento')"
          :error="
            errors.collect('adm_filiais.complemento').length ? true : false
          "
          v-validate="'required'"
          data-vv-scope="adm_filiais"
          data-vv-validate-on="change|blur|input"
          id="complemento"
          name="complemento"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "FiliaisForm",
  inject: ["$validator"],
  data() {
    return {
      loadings: {
        loadingCep: false,
        loadingResponsavel: true,
      },
    };
  },
  created() {
    const dataResponsavel = {
      columns: ["id", "nome"],
      validate: [["status", "A"]],
    };

    this.$store
      .dispatch("usuarios/getDataByColumn", dataResponsavel)
      .finally(() => {
        this.loadings.loadingResponsavel = false;
      });
  },
  methods: {
    /**
     * @description Busca pelo cep utilizando o viaCep.
     */
    searchCep() {
      if (this.filial.endereco.cep.length > 7) {
        this.loadings.loadingCep = true;
        const cepFiltred = this.filial.endereco.cep.replace("-", "");

        axios
          .get(`https://viacep.com.br/ws/${cepFiltred}/json/`)
          .then((response) => {
            this.filial.endereco.cep = response.data.cep;
            this.filial.endereco.rua = response.data.logradouro;
            this.filial.endereco.bairro = response.data.bairro;
            this.filial.endereco.complemento = response.data.complemento;
            this.filial.endereco.uf = response.data.uf;
            this.filial.endereco.cidade = response.data.localidade;
          })
          .catch(() => {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: "CEP não encontrado, tente outro.",
              cor: "red",
            });
          })
          .finally(() => {
            this.loadings.loadingCep = false;
          });
      }
    },
  },
  computed: {
    filial: {
      get() {
        return this.$store.getters["filiais/getFilial"];
      },
      set(value) {
        this.$store.dispatch("filiais/setFilial", value);
      },
    },
    responsaveis: {
      get() {
        return this.$store.getters["usuarios/getResponsaveis"];
      },
    },
  },
};
</script>

<style></style>
