<template>
  <section name="filiais">
    <v-row>
      <v-col cols="12">
        <Crud
          title="Gestão de filial"
          buttonAddItemText="Nova filial"
          searchLabelText="Pesquisar na lista"
          :searchTextField.sync="searchTextField"
          :formTitle="nameForm"
          :callBackModalCancelar="fechar"
          :dialog.sync="dialog"
          :callBackModalSalvar="salvar"
          :dialogDelete.sync="dialogDelete"
        >
          <template slot="data-content">
            <v-row class="mt-5">
              <!-- FILTRO CAMPOS -->
            </v-row>
          </template>
          <template slot="data-table">
            <v-data-table
              :headers="headers"
              :items="filiais"
              class="elevation-5"
              items-per-page-text="Linhas por página"
              no-data-text="Nenhum registro encontrado"
              :loading="loadingTable"
            >
              <!-- ACTIONS -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <!-- Visualizar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon
                                class="actions"
                                @click="toggleModalLateral(item)"
                              >
                                mdi-eye
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Visualizar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>

                    <!-- Editar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon class="actions" @click="editar(item)">
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>

                    <!-- Deletar -->
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon
                                class="actions"
                                @click="remover(item, true)"
                              >
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Deletar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                <span
                  >{{ item.created_at | dateFormatBr() }}
                  {{ item.created_at | timeFormatBr() }}h</span
                >
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-switch
                  class="mt-0 pt-0"
                  v-model="item.status"
                  @click="mudarStatus(item)"
                  :label="item.status == 'A' ? 'Ativo' : 'Inativo'"
                  color="success"
                  true-value="A"
                  false-value="I"
                  hide-details
                ></v-switch>
              </template>
              <template v-slot:[`item.cnpj`]="{ item }">
                <span>{{ item.cnpj | cnpjMask() }}</span>
              </template>
            </v-data-table>
          </template>
          <!--------------- MODAL DE DELETE --------------------------------------------- -->
          <template slot="modalDelete/titleModal">
            Excluir o registro?
          </template>
          <template slot="modalDelete/contentModal">
            <p>
              Atenção! Você está prestes a realizar uma ação que não pode ser
              desfeita.
            </p>
            <p>Você realmente deseja excluir esse registro?</p>
          </template>
          <template slot="modalDelete/actionsModal">
            <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
            <v-btn
              :loading="loadingButtonDelete"
              color="error"
              @click="remover()"
            >
              Excluir
            </v-btn>
            <v-spacer class="d-none d-md-block"></v-spacer>
          </template>
          <!-- ------------- MODAL DE CADASTRO --------------------------------------------- -->
          <template slot="contentModalCrud">
            <FiliaisForm />
          </template>
        </Crud>
      </v-col>
    </v-row>
    <ModalLateral
      :show="modalLateral.show"
      :hide="toggleModalLateral"
      :title="modalLateral.title"
      :headers="headerModalLateral"
      :item="modalLateral.item"
    >
      <template v-slot:[`item.cnpj`]="{ item }">
        <span>{{ item.cnpj | cnpjMask() }}</span>
      </template>
      <template v-slot:[`item.telefone`]="{ item }">
        <span>{{ item.telefone | maskForTel() }}</span>
      </template>
      <template v-slot:[`item.celular`]="{ item }">
        <span>{{ item.celular | maskForCel() }}</span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="item.status == 'A' ? 'success' : 'secondary'">{{
          item.status == "A" ? "Ativo" : "Inativo"
        }}</v-chip>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <span
          >{{ item.created_at | dateFormatBr() }}
          {{ item.created_at | timeFormatBr() }}h</span
        >
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        <span
          >{{ item.updated_at | dateFormatBr() }}
          {{ item.updated_at | timeFormatBr() }}h</span
        >
      </template>
    </ModalLateral>
  </section>
</template>

<script>
import Crud from "@/components/Crud.vue";
import ModalLateral from "@/components/ModalLateral.vue";
import FiliaisForm from "./forms/FiliaisForm.vue";

export default {
  name: "Filiais",
  inject: ["$validator"],
  components: { Crud, ModalLateral, FiliaisForm },
  data: () => ({
    loadingTable: false,
    nameForm: "Nova Filial",
    modalLateral: {
      show: false,
      item: [],
      title: "",
    },
    searchTextField: "",
    dialog: false,
    indexEdicao: -1,
    headers: [
      { text: "Nome", align: "start", value: "nome" },
      { text: "Responsável", align: "start", value: "responsavel.nome" },
      { text: "CNPJ", align: "start", value: "cnpj" },
      { text: "Status", value: "status" },
      { text: "Data de criação", value: "created_at" },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    headerModalLateral: [
      { text: "Responsável", value: "responsavel.nome" },
      { text: "CNPJ", value: "cnpj" },
      { text: "Telefone", value: "telefone" },
      { text: "Celular", value: "celular" },
      { text: "Descrição", value: "descricao" },
      { text: "CEP", value: "endereco.cep" },
      { text: "UF", value: "endereco.uf" },
      { text: "Cidade", value: "endereco.cidade" },
      { text: "Bairro", value: "endereco.bairro" },
      { text: "Rua", value: "endereco.rua" },
      { text: "Número", value: "endereco.numero" },
      { text: "Complemento", value: "endereco.complemento" },
      { text: "Data de criação", value: "created_at" },
      { text: "Status", value: "status" },
    ],

    // Data ModalDelete
    dialogDelete: false,
    loadingButtonDelete: false,
    item: [],
  }),

  created() {
    this.loadingTable = true;

    this.$store
      .dispatch("filiais/initialize", this.$route.params.uuid)
      .finally(() => {
        this.loadingTable = false;
      });
  },

  computed: {
    filial: {
      get() {
        return this.$store.getters["filiais/getFilial"];
      },
      set(value) {
        this.$store.dispatch("filiais/setFilial", value);
      },
    },
    filiais: {
      get() {
        return this.$store.getters["filiais/getFiliais"];
      },
    },
  },

  methods: {
    toggleModalLateral(item) {
      if (this.modalLateral.show == false) {
        this.loadingTable = true;

        this.$store
          .dispatch("filiais/findByUuid", item.uuid)
          .then((response) => {
            this.modalLateral.title = response.nome;
            this.modalLateral.item = response ? response : [];
            this.modalLateral.show = true;
          })
          .catch(() => {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem:
                "Houve um erro ao buscar todos os dados do filial selecionada.",
              cor: "red",
            });
          })
          .finally(() => {
            this.loadingTable = false;
          });
        return;
      } else {
        this.modalLateral.show = false;
      }
    },
    salvar() {
      this.$validator.validate("adm_filiais.*").then((result) => {
        if (result) {
          this.dialog = false;
          this.loadingTable = true;
          const isEdit = this.filial.id ? 1 : -1;

          const url =
            isEdit === -1 ? "/filiais" : "/filiais/" + this.filial.uuid;
          const method = isEdit === -1 ? "POST" : "PUT";
          const sucesso =
            isEdit === -1
              ? "Filial cadastrada com sucesso."
              : "Filial atualizada com sucesso.";
          const erro =
            isEdit === -1
              ? "Erro ao salvar filial."
              : "Erro ao atualizar filial.";

          this.tratativeCnpj().tratativeCell().tratativeTelephone();

          let filialItem = this.filial;
          filialItem._method = method;
          filialItem.url = url;
          filialItem.endereco.categoria = "FILIAL";

          this.$store
            .dispatch("filiais/save", filialItem)
            .then((response) => {
              if (isEdit == 1) {
                const index = this.filiais.findIndex(
                  (el) => el.id == filialItem.id
                );
                const responsaveis =
                  this.$store.getters["usuarios/getResponsaveis"];

                const newResponsavel = responsaveis.find(
                  (el) => el.id == filialItem.id_usuario
                );
                filialItem.responsavel = newResponsavel;

                Object.assign(this.filiais[index], filialItem);
              } else {
                this.filiais.push(response);
              }

              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                mensagem: sucesso,
                cor: "green",
              });
            })
            .catch(() => {
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                mensagem: erro,
                cor: "red",
              });
            })
            .finally(() => {
              this.fechar();
            });
        }
      });
    },
    /**
     * @description Retira a mascara do cnpj deixando somente números.
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    tratativeCnpj() {
      if (this.filial && this.filial.cnpj) {
        this.filial.cnpj = this.filial.cnpj.replace(/[^\d]+/g, "");
      }

      return this;
    },
    /**
     * @description Retira a mascara do celular deixando somente números.
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    tratativeCell() {
      if (this.filial && this.filial.celular) {
        this.filial.celular = this.filial.celular.replace(/[^\d]+/g, "");
      }

      return this;
    },
    /**
     * @description Retira a mascara do telefone deixando somente números.
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    tratativeTelephone() {
      if (this.filial && this.filial.telefone) {
        this.filial.telefone = this.filial.telefone.replace(/[^\d]+/g, "");
      }

      return this;
    },
    editar(item) {
      this.loadingTable = true;

      this.$store
        .dispatch("filiais/findByUuid", item.uuid)
        .then((response) => {
          this.filial = response;

          if (this.filial.endereco == null) {
            this.filial.endereco = {
              cep: "",
              rua: "",
              numero: 0,
              bairro: "",
              complemento: "",
              uf: "",
              cidade: "",
            };
          }

          this.nameForm = "Editar filial: " + this.filial.nome;
          this.dialog = true;
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem:
              "Houve um erro ao buscar todos os dados do filial selecionada.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    fechar() {
      this.loadingTable = false;
      this.dialog = false;
      this.nameForm = "Nova filial";
      this.filial = {
        nome: "",
        cnpj: "",
        email: "",
        celular: "",
        telefone: "",
        id_usuario: "",
        descricao: "",
        endereco: {
          cep: "",
          rua: "",
          numero: 0,
          bairro: "",
          complemento: "",
          uf: "",
          cidade: "",
        },
      };
    },
    remover(item, openModal = false) {
      if (openModal) {
        this.item = Object.assign({}, item);
        this.dialogDelete = true;
      } else {
        this.loadingButtonDelete = true;
        const indexItem = this.filiais.findIndex((el) => el.id == this.item.id);

        this.$store
          .dispatch("filiais/remove", this.item.uuid)
          .then((response) => {
            if (response) {
              this.filiais.splice(indexItem, 1);

              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Filial removida.",
              });
              this.item = {};
            }
          })
          .catch(() => {
            this.$store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possível remover a filial.",
            });
          })
          .finally(() => {
            this.loadingButtonDelete = false;
            this.dialogDelete = false;
          });
      }
    },
    mudarStatus(item) {
      this.loadingTable = true;

      const filialItem = {
        status: item.status,
        _method: "PUT",
        url: `/filiais/${item.uuid}`,
      };

      this.$store
        .dispatch("filiais/save", filialItem)
        .then((response) => {
          if (response != "NAO_AUTORIZADO") {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: "Status da filial atualizada com sucesso.",
              cor: "green",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Erro ao atualizar status da filial.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
  },
};
</script>

<style scoped></style>
